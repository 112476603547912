<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button type="button" @click="goBack()" class="btn btn-inverse">
            Kembali
          </button>
        </span>
      </h3>
      <hr />

      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div class="col-md-6 pull-left">
            <small class="text-muted">Manual Book</small>
            <h6>
              {{
                state.detail.manual_book === "" ? "-" : state.detail.manual_book
              }}
            </h6>
          </div>
        </div>
      </div>

      <hr />

      <div class="col-md-12">
        <br />
        <h6
          v-if="state.detail.files == null || state.detail.files == undefined"
        >
          -
        </h6>
        <h6 v-else-if="!state.detail.files.length">-</h6>
        <table v-else class="table table-stripped">
          <tr>
            <th>File</th>
            <th>Aksi</th>
          </tr>
          <tr v-for="file in state.detail.files" v-bind:key="file.filename">
            <td>
              {{ file.filename }}
            </td>
            <td>
              <a
                class="btn btn-success text-white"
                @click="downloadForce(file.url)"
                title="Download File"
                ><i class="fa fa-download text-white"></i></a
              >&nbsp;
              <a
                @click="prev(file.url)"
                class="btn btn-info text-white"
                title="View File"
                ><i class="fa fa-eye text-white"></i
              ></a>
              <!-- <a
                        class="btn btn-info text-white"
                        @click="downloadForce(file.url)"
                        title="View File"
                        ><i class="fa fa-eye text-white"></i
                      ></a> -->
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
// import ImageItem from "../../../components/img/imgItem";
export default {
  components: {
    RotateSquare5,
    // ImageItem
  },
  computed: {
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.manualBook;
    },
    tanggalditerima() {
      return this.formatDate(this.state.detail.tanggal_diterima);
    },
    tanggaldokumen() {
      return this.formatDate(this.state.detail.tanggal_dokumen);
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false,
      };
      this.$store.commit("manualBook/STATE", state);
      this.$store.dispatch("manualBook/getManualBookById", this.$route.params);
    },
    goBack() {
      this.$store.dispatch("manualBook/onCancel");
    },
    downloadForce(url) {
      const downloadLink = document.createElement("a");

      downloadLink.href = url;
      downloadLink.target = "_blank";
      downloadLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },

    prev(url) {
      const downloadLink = document.createElement("a");

      downloadLink.href = url;
      downloadLink.target = "_blank";
      downloadLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },

    // prev(string) {
    //   let routeData = this.$router.resolve({
    //     name: "preview.index",
    //     params: {
    //       document_type: "dokumen_pergub",
    //       id: this.$route.params.id,
    //       file: string,
    //     },
    //   });
    //   window.open(routeData.href, "_blank");
    // },
  },
};
</script>
